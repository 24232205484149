var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "publish-" + _vm.item.slug,
      attrs: {
        id: "publish-" + _vm.item.slug,
        centered: "",
        "no-close-on-backdrop": "",
        "footer-class": "flex-nowrap"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [_vm._v(_vm._s(_vm.$t("modals.publishItem.title")))])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function({ close, ok }) {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3 border-right-light-grey",
                  attrs: { block: "" },
                  on: { click: _vm.cancelItem }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { variant: "primary", block: "" },
                  on: { click: _vm.publishItem }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "publish-icon text-center px-2" },
        [
          _c("b-icon", {
            staticClass: "mt-5 mb-4",
            attrs: { icon: "exclamation-circle-fill", variant: "success" }
          }),
          _c("div", {
            staticClass: "mt-2 mb-4",
            domProps: {
              innerHTML: _vm._s(_vm.$t("modals.publishItem.message"))
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }