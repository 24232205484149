<template>
  <b-modal
    :id="'publish-'+item.slug"
    :ref="'publish-'+item.slug"
    centered
    no-close-on-backdrop
    footer-class="flex-nowrap"
  >
    <template #modal-header>
      <h3>{{ $t('modals.publishItem.title') }}</h3>
    </template>
    <div class="publish-icon text-center px-2">
      <b-icon icon="exclamation-circle-fill" variant="success" class="mt-5 mb-4"></b-icon>
      <div class="mt-2 mb-4" v-html="$t('modals.publishItem.message')"></div>
    </div>
    <template #modal-footer="{ close, ok }">
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="cancelItem"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="publishItem"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';

export default {
  name: 'ModalPublishItem',
  props: ['item'],
  mounted() {
    this.$bvModal.show(`publish-${this.item.slug}`);
  },
  methods: {
    async publishItem() {
      this.$emit('publish-item', this.item);
      this.$refs[`publish-${this.item.slug}`].hide();
      await this.$apollo.mutate({
        mutation: SetLoadingIndicatorInCache,
        variables: {
          isIndicatorLoading: true,
        },
      });
    },
    cancelItem() {
      this.$emit('publish-cancel');
      this.$refs[`publish-${this.item.slug}`].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.publish-icon svg {
  font-size: 80px;
}
</style>
